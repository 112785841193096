/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@tabler/core@1.0.0-beta17/dist/css/tabler.min.css
 * - /npm/@tabler/core@1.0.0-beta17/dist/css/demo.min.css
 * - /npm/@tabler/core@1.0.0-beta17/dist/css/tabler-flags.min.css
 * - /npm/@tabler/core@1.0.0-beta17/dist/css/tabler-payments.min.css
 * - /npm/@tabler/core@1.0.0-beta17/dist/css/tabler-vendors.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
